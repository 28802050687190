import {TypeMapper} from '../../../../shared/models/TypeMapper';
import {ChartValue} from '../../../../shared/modules/charts/models/ChartValue';

export class ForwarderBenefitsRangeMapper extends TypeMapper<JSON, ChartValue> {
  private readonly monthsAmount: number;

  constructor(input: any, monthsAmount: number) {
    super(input);
    this.monthsAmount = monthsAmount;
  }

  map(): ChartValue {
    const range: ChartValue = new ChartValue();
    const currentMonth = new Date().getMonth();
    const totalLength = Object.keys(this.input).length;
    const normalValues = new ChartValue();
    const invertedValues = new ChartValue();
    const keys = Object.keys(this.input);
    let totalValuesPushed = 0;

    for (let index = keys.length - 1; index >= 0; index--) {
      const key = keys[index];

      if (index <= currentMonth && totalValuesPushed < this.monthsAmount) {
        if (this.monthsAmount > 3) {
          const label = key.slice(0, 3);
          normalValues.labels.push(label);
        } else {
          normalValues.labels.push(key);
        }
        totalValuesPushed++;
        normalValues.series[0].push(this.input[key]);
      }

      // Inverted values to show older months values at the beginning
      if (index > totalLength - (this.monthsAmount - currentMonth)  && totalValuesPushed < this.monthsAmount) {
        if (this.monthsAmount > 3) {
          const label = key.slice(0, 3);
          invertedValues.labels.push(label);
        } else {
          invertedValues.labels.push(key);
        }
        totalValuesPushed++;
        invertedValues.series[0].push(this.input[key]);
      }
    }

    range.labels = invertedValues.labels.reverse().concat(normalValues.labels.reverse());
    range.series[0] = invertedValues.series[0].reverse().concat(normalValues.series[0].reverse());
    return range;
  }
}
