import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CompanyExpensesByMonthMapper} from '../models/CompanyExpensesByMonthMapper';
import {CompanyShipmentsByCountry} from '../models/CompanyShipmentsByCountry';
import {CompanyShipmentsByCountryTableMapper} from '../models/CompanyShipmentsByCountryTableMapper';

@Injectable({
  providedIn: 'root'
})
export class CompanyShipAnalyticsService {
  private static BENEFITS_ENDPOINT = environment.baseApiUrl + 'get-company-expenses';
  private static TOTAL_SHIPMENTS_ENDPOINT = environment.baseApiUrl + 'get-total-company-shipments';
  private static SHIPMENTS_BY_MONTH_ENDPOINT = environment.baseApiUrl + 'get-company-expenses-by-month';
  private static SHIPMENTS_BY_COUNTRY_ENDPOINT = environment.baseApiUrl + 'get-company-shipments-by-country';

  constructor(private http: HttpClient) {
  }

  getBenefits() {
    return this.http.get(CompanyShipAnalyticsService.BENEFITS_ENDPOINT).map(value => value as CompanyExpensesWrapper);
  }

  getTotalShipments() {
    return this.http.get(CompanyShipAnalyticsService.TOTAL_SHIPMENTS_ENDPOINT).map(value => value as CompanyTotalShipmentsWrapper);
  }

  getExpensesByMonth() {
    return this.http.get(CompanyShipAnalyticsService.SHIPMENTS_BY_MONTH_ENDPOINT)
      .map(value => new CompanyExpensesByMonthMapper(value as JSON).map());
  }

  getShipmentsByCountry() {
    return this.http.get(CompanyShipAnalyticsService.SHIPMENTS_BY_COUNTRY_ENDPOINT)
      .map(value => new CompanyShipmentsByCountryTableMapper(value as CompanyShipmentsByCountry[]).map());
  }
}

