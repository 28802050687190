import {TypeMapper} from '../../../../shared/models/TypeMapper';
import {DateRangeSelectorComponent} from '../../../../shared/modules/charts/date-range-selector/date-range-selector.component';
import {ForwarderBenefitsByMonth} from './ForwarderBenefitsByMonth';
import {ForwarderBenefitsRangeMapper} from './ForwarderBenefitsRangeMapper';

export class ForwarderBenefitsByMonthMapper extends TypeMapper<JSON, ForwarderBenefitsByMonth> {
  map(): ForwarderBenefitsByMonth {
    const expensesByMonth = new ForwarderBenefitsByMonth();
    Object.keys(this.input).forEach(key => {
      expensesByMonth[key] = new ForwarderBenefitsRangeMapper(this.input[key], DateRangeSelectorComponent.getMonthsAmount(key)).map();
    });
    return expensesByMonth;
  }
}
