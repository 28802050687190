import {TypeMapper} from '../../../../shared/models/TypeMapper';
import {CompanyShipmentsByCountry} from './CompanyShipmentsByCountry';

export class CompanyShipmentsByCountryTableMapper extends TypeMapper<CompanyShipmentsByCountry[], string[][]> {
  map(): string[][] {
    const dataRows = [];
    const totalShipmentsAmount = this.getTotalShipmentsAmount();
    this.input.forEach(country => {
      const perCent = ((country.shipments * 100) / totalShipmentsAmount).toFixed(2);
      const dataRow = [country.code, country.name, country.shipments + '', perCent + '%'];
      dataRows.push(dataRow);
    });
    return dataRows;
  }

  private getTotalShipmentsAmount() {
    let totalAmount = 0;
    this.input.forEach(country => {
      totalAmount += country.shipments;
    });
    return totalAmount;
  }
}
