import {TypeMapper} from '../../../../shared/models/TypeMapper';
import {CompanyExpensesByMonth} from './CompanyExpensesByMonth';
import {CompanyExpensesRangeMapper} from './CompanyExpensesRangeMapper';
import {DateRangeSelectorComponent} from '../../../../shared/modules/charts/date-range-selector/date-range-selector.component';

export class CompanyExpensesByMonthMapper extends TypeMapper<JSON, CompanyExpensesByMonth> {
  map(): CompanyExpensesByMonth {
    const expensesByMonth = new CompanyExpensesByMonth();
    Object.keys(this.input).forEach(key => {
      expensesByMonth[key] = new CompanyExpensesRangeMapper(this.input[key], DateRangeSelectorComponent.getMonthsAmount(key)).map();
    });
    return expensesByMonth;
  }
}
