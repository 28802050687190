import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ForwarderBenefitsByMonthMapper} from '../models/ForwarderBenefitsByMonthMapper';
import {ForwarderShipmentsByCountryTableMapper} from '../models/ForwarderShipmentsByCountryTableMapper';
import {ForwarderShipmentsByCountry} from '../models/ForwarderShipmentsByCountry';

@Injectable({
  providedIn: 'root'
})
export class ForwarderShipAnalyticsService {
  private static BENEFITS_ENDPOINT = environment.baseApiUrl + 'get-forwarder-benefits';
  private static TOTAL_SHIPMENTS_ENDPOINT = environment.baseApiUrl + 'get-total-forwarder-shipments';
  private static BENEFITS_BY_MONTH_ENDPOINT = environment.baseApiUrl + 'get-forwarder-benefits-by-month';
  private static SHIPMENTS_BY_COUNTRY_ENDPOINT = environment.baseApiUrl + 'get-forwarder-shipments-by-country';

  constructor(private http: HttpClient) {
  }

  getBenefits() {
    return this.http.get(ForwarderShipAnalyticsService.BENEFITS_ENDPOINT).map(value => value as ForwarderBenefitsWrapper);
  }

  getTotalShipments() {
    return this.http.get(ForwarderShipAnalyticsService.TOTAL_SHIPMENTS_ENDPOINT).map(value => value as ForwarderTotalShipmentsWrapper);
  }

  getBenefitsByMonth() {
    return this.http.get(ForwarderShipAnalyticsService.BENEFITS_BY_MONTH_ENDPOINT)
      .map(value => new ForwarderBenefitsByMonthMapper(value as JSON).map());
  }

  getShipmentsByCountry() {
    return this.http.get(ForwarderShipAnalyticsService.SHIPMENTS_BY_COUNTRY_ENDPOINT)
      .map(value => new ForwarderShipmentsByCountryTableMapper(value as ForwarderShipmentsByCountry[]).map());
  }
}

